export const environment = {
  production: true,
  svAPIKey: "EST321",
  baseUrl: "https://production.public.api.lafka.dev/api/v1",
  googleAppId: "",
  facebookAppId: "",
  firebase: {
    apiKey: "AIzaSyAxYWvFZXbDWE2mbZb19it5-pb1y3I98eA",
    authDomain: "estradi-prod.firebaseapp.com",
    projectId: "estradi-prod",
    storageBucket: "estradi-prod.appspot.com",
    messagingSenderId: "255179785865",
    appId: "1:255179785865:web:b8f7ff6985f7bbf572e858",
    measurementId: "G-1G0NTGR24T",
  },
};
